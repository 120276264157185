// TODO: Home is not an "entity" in our DB, so this breaks RESTful patterns
// These calls should be moved away into their entities
// e.g Mindset belongs to a `user` so it should be a PATCH /user/id
// The agreggate endpoints should be handled differently, the better
// approach is to properly sperate the components, and have each of those
// retreive their own information, rather than having a single endpoint
// doing everything
import type { Client, Coach, Mindset } from '@/types';

export const getHome = () => {};

export const updateMindset = async (
  host: string,
  isClient: boolean,
  user: Client | Coach,
  mindSet: Mindset,
): Promise<Client | Coach | null> => {
  let updated;
  const path = isClient ? 'clients' : 'coaches';
  try {
    const response = await fetch(`${host}/api/${path}/${user.id}`, {
      body: JSON.stringify({
        mindSet,
      }),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
    });

    updated = await response.json();
  } catch (e) {
    console.error(e);
  }

  return updated;
};

export type GetHomeParams = {
  host: string;
};
export const fetchHome = async ({ host }: GetHomeParams) => {
  try {
    return await (
      await fetch(`${host}/api/home`, { credentials: 'include' })
    ).json();
  } catch (e) {
    console.error(e);
    throw e;
  }
};

// TODO: Throwing this here for lack of a better place
// API goes away with, again, properly split components
// each loading its own data from RESTful endpoints
export const getClientOverview = async (
  host: string,
  clientId: string | undefined,
) => {
  if (!clientId) {
    return Promise.resolve({});
  }
  return (
    await fetch(`${host}/api/clients/${clientId}/overview`, {
      credentials: 'include',
    })
  ).json();
};
