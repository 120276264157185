import { Table, TableContainer } from '@/ui';

import List from './List';
import Header from './List/Header';

const Members = () => (
  <div className="flex flex-1 flex-col pt-4">
    <TableContainer>
      <Table aria-label="companies table">
        <Header />
        <List />
      </Table>
    </TableContainer>
  </div>
);

export default Members;
