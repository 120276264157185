import { useNavigate } from 'react-router-dom';

import { useGetDocuments, useView } from '@/hooks';
import { Button, Card, CardActions, CardContent, Typography } from '@/ui';

import Loading from './DocumentsLoading';
import Empty from './Empty';
import Table from './Table';

import type { Client } from '@/types';
import type { FC } from 'react';

const RecentlyUpdatedDocuments: FC<{
  client: Client;
}> = ({ client }) => {
  const { selectedUser } = useView();
  const { data: documents = { items: [] }, isLoading } = useGetDocuments({
    page: 1,
    userId: selectedUser.id,
  });
  const navigate = useNavigate();
  const onViewAll = () => navigate(`/coach/members/${client.id}/documents`);

  if (isLoading) return <Loading />;
  if (!documents.items.length) return <Empty />;

  return (
    <Card>
      <CardContent>
        <Typography className="mb-5" variant="subtitle1">
          Recent Documents
        </Typography>

        <Table documents={documents.items} />
      </CardContent>
      <CardActions>
        <Button
          className="ml-auto w-full md:w-auto"
          variant="outlined"
          onClick={onViewAll}
        >
          See all documents ({documents.items.length})
        </Button>
      </CardActions>
    </Card>
  );
};

export default RecentlyUpdatedDocuments;
