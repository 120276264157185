import { ZERO } from '@/constants';
import { Typography } from '@/ui';
import { toDateTimeString } from '@/utils';

import type { FC } from 'react';

interface SessionColumnProps {
  done?: number;
  next?: string;
  pending?: number;
  timezone?: string;
}

const SessionColumn: FC<SessionColumnProps> = ({
  done,
  next,
  pending,
  timezone = 'America/Los_Angeles',
}) => (
  <div>
    <Typography
      className="flex items-center justify-between gap-2"
      color={!pending ? 'error.main' : 'text.primary'}
      variant="subtitle2"
    >
      {next
        ? toDateTimeString(new Date(next), timezone, 'short', 'short')
        : 'No upcoming sessions'}
    </Typography>
    <Typography
      className="flex items-center justify-between gap-2"
      color="text.secondary"
      variant="caption"
    >
      {pending ?? ZERO} upcoming • {done ?? ZERO} completed
    </Typography>
  </div>
);

export default SessionColumn;
