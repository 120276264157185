import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { updateHubspotContact, updateUser, updateUserProfile } from '@/api';
import { JOB_EXPERIENCE, JOB_TITLES } from '@/constants';
import { useCurrentUser } from '@/hooks';
import { Button, CreatableSelect, MenuItem, TextField, Typography } from '@/ui';
import { alias, track, urlSafeEmail } from '@/utils';

import type { AboutForm } from '@/types';
import type { ChangeEvent } from 'react';
import type { ControllerRenderProps } from 'react-hook-form';

import { notifySlack } from '@/api/zapier';
import { GetStartedContext } from '@/components/GetStarted/context';
import { ZapTypes } from '@/types/zapier';

const About = () => {
  const { user, isUserLoading, refetchUser } = useCurrentUser();
  const { nextStep } = useContext(GetStartedContext);

  const {
    handleSubmit,
    control,
    formState: { errors, isValid } = {},
    reset,
  } = useForm<AboutForm>({
    defaultValues: {
      company: '',
      experience: '',
      firstName: '',
      lastName: '',
      title: '',
    },
    mode: 'onChange',
  });
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    track('ob_about');
  }, []);

  useEffect(() => {
    if (user.id) {
      reset({
        company: user.currentCompany?.name ?? '',
        experience: user.profile?.experience ?? '',
        firstName: user.firstName.includes('@') ? '' : user.firstName,
        lastName: user.lastName,
        title: user.profile?.title ?? '',
      });
    }
  }, [user]);

  const onFirstNameChange =
    (field: ControllerRenderProps<AboutForm, 'firstName'>) =>
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      field.onChange(e.target.value);

  const onLastNameChange =
    (field: ControllerRenderProps<AboutForm, 'lastName'>) =>
    (e: ChangeEvent<HTMLInputElement>) =>
      field.onChange(e.target.value);

  const onTitleChange =
    (field: ControllerRenderProps<AboutForm, 'title'>) =>
    (value: string | null) =>
      field.onChange(value);

  const onCompanyChange =
    (field: ControllerRenderProps<AboutForm, 'company'>) =>
    (e: ChangeEvent<HTMLInputElement>) =>
      field.onChange(e.target.value);

  const onExperienceChange =
    (field: ControllerRenderProps<AboutForm, 'experience'>) =>
    (e: ChangeEvent<HTMLInputElement>) =>
      field.onChange(e.target.value);

  const onNext = async (data: Partial<AboutForm>) => {
    try {
      const firstName = (data.firstName ?? '').trim();
      const lastName = (data.lastName ?? '').trim();
      const title = (data.title ?? '').trim();
      const company = (data.company ?? '').trim();
      const experience = (data.experience ?? '').trim();

      setSaving(true);
      track('ob_about_cont');

      const name = `${firstName} ${lastName}`;

      // Set Mixpanel info
      alias(user.email);

      await Promise.allSettled([
        // TODO: MOVE TO BE
        // First instance of `updateHubspotContact` for a given email will create the contact in Hubspot as long as it is in Production
        // Hubspot expects certain values so we have to send those
        updateHubspotContact(user.email, {
          company,
          email: user.email,
          experience,
          firstname: firstName,
          full_name: name,
          jobtitle: title,
          lastname: lastName,
          lifecyclestage: 'opportunity',
        }),
        // Update models
        updateUser(user.id, { firstName, lastName }),
        updateUserProfile(user.id, { company, experience, title }),
      ]);
      await refetchUser();
      // Notify Slack of Quiz Started
      if (user.isBusinessPaid) {
        const businessStartedDataUrl = `/silent?email=${urlSafeEmail(
          user.email,
        )}&company=${company}`;

        await notifySlack(
          ZapTypes.BUSINESS_STARTED_QUIZ,
          businessStartedDataUrl,
        );
      } else {
        const dataUrl = `/silent?email=${urlSafeEmail(user.email)}`;
        await notifySlack(ZapTypes.USER_STARTED_QUIZ, dataUrl);
      }
      nextStep();
    } catch (e) {
      console.error(e);
    } finally {
      setSaving(false);
    }
  };

  // Not sure if strictly needed, but might help avoid issues
  if (isUserLoading) return null;

  return (
    <main className="bg-gradient-dark-sunny flex flex-1 flex-col overflow-auto">
      <div className="flex flex-col pb-20 md:pb-0">
        <div className="container mx-auto overflow-auto p-4 text-center md:mt-4">
          <Typography className="mb-5" variant="h4">
            Before we start, tell us about&nbsp;
            <span className="bg-gradient-to-br from-poppy-500 to-bloom-500 bg-clip-text text-transparent">
              yourself!
            </span>
          </Typography>
          <Typography className="mx-auto mb-8 max-w-100" variant="body1">
            This will help us understand your current experience and match you
            with the right coach.
          </Typography>
          <form
            className="mx-auto flex max-w-screen-md items-center justify-center"
            id="create-user"
            onSubmit={handleSubmit(onNext)}
          >
            <div className="flex w-full max-w-118 flex-col gap-6">
              <Controller
                control={control}
                name="firstName"
                render={({ field }) => (
                  <TextField
                    error={!!errors?.firstName}
                    helperText={errors?.firstName && 'First name is required'}
                    label="First name"
                    value={field.value}
                    onChange={onFirstNameChange(field)}
                  />
                )}
                rules={{ required: true }}
              />
              <Controller
                control={control}
                name="lastName"
                render={({ field }) => (
                  <TextField
                    error={!!errors?.lastName}
                    helperText={errors?.lastName && 'Last name is required'}
                    label="Last name"
                    value={field.value}
                    onChange={onLastNameChange(field)}
                  />
                )}
                rules={{ required: true }}
              />
              <Controller
                control={control}
                name="title"
                render={({ field }) => (
                  <CreatableSelect
                    helperText={errors?.title && 'Title is required'}
                    label="Current or recent job title"
                    options={JOB_TITLES}
                    value={field.value}
                    createOnBlur
                    onChange={onTitleChange(field)}
                  />
                )}
                rules={{ required: true }}
              />
              <Controller
                control={control}
                name="company"
                render={({ field }) => (
                  <TextField
                    disabled={!!user.currentCompany?.name}
                    error={!!errors?.company}
                    helperText={errors?.company && 'Company is required'}
                    label={`Current${
                      user.profile?.company ? ' or recent' : ''
                    } company`}
                    value={field.value}
                    onChange={onCompanyChange(field)}
                  />
                )}
                rules={{ required: true }}
              />
              <Controller
                control={control}
                name="experience"
                render={({ field }) => (
                  <TextField
                    className="text-left"
                    label="Years of experience"
                    value={field.value}
                    select
                    onChange={onExperienceChange(field)}
                  >
                    {JOB_EXPERIENCE.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                rules={{ required: true }}
              />
            </div>
          </form>
        </div>
      </div>
      <div className="fixed bottom-0 mt-auto w-full px-6 pb-4 text-center text-sm md:static md:mb-6 md:mt-0 md:py-6">
        <Button
          className="w-full min-w-72 md:w-72"
          color="primary"
          disabled={!isValid}
          form="create-user"
          loading={saving}
          type="submit"
          variant="contained"
        >
          Continue
        </Button>
      </div>
    </main>
  );
};

export default About;
