// This one is hostless because it only exists in client
export const updateHubspotContact = async (
  email: string,
  properties: object,
): Promise<void> => {
  await fetch(`${import.meta.env.VITE_CLIENT_BACKEND_URL}/api/contact`, {
    body: JSON.stringify({
      email,
      properties,
    }),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });
};
