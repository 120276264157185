import clsx from 'clsx';

import {
  COMPANY_MEMBER_HEADERS,
  ONE,
  VISIBLE_COMPANY_MEMBER_HEADERS,
} from '@/constants';
import { TableCell, TableHead, TableRow, Typography } from '@/ui';

const Header = () => (
  <TableHead>
    <TableRow>
      {COMPANY_MEMBER_HEADERS.map((header, index) => (
        <TableCell
          key={`${header}-company-members-table`}
          className={clsx('md:table-cell', {
            hidden:
              index > VISIBLE_COMPANY_MEMBER_HEADERS &&
              index !== COMPANY_MEMBER_HEADERS.length - ONE,
          })}
        >
          <Typography variant="subtitle2">{header}</Typography>
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

export default Header;
