import { track } from '@/utils';

import { useUpdateClientEvent } from '../events';
import { useView } from '../useView';

import type { HomeCardPropsWithoutCallbacks } from '@/types';

const useSharedCardActions = () => {
  const { host } = useView();
  const updateClientEvent = useUpdateClientEvent();

  const handleClick = ({
    shouldUpdate = true,
    label = '',
    type = 'primary',
    card,
  }: {
    card: HomeCardPropsWithoutCallbacks;
    label: string;
    shouldUpdate?: boolean;
    type?: 'primary' | 'secondary';
  }) => {
    track('Clicked CTA of action card', {
      clientId: card.client?.id,
      cta: type,
      ctaText: label,
      label: card.label,
      title: card.title,
    });
    if (shouldUpdate) {
      updateClientEvent.mutate({
        host,
        key: card.type,
        viewedAt: new Date().toISOString(),
      });
    }
  };

  return {
    handleClick,
  };
};

export default useSharedCardActions;
