import { TEN } from '@/constants';
import { useCurrentUser, useView } from '@/hooks';
import { NoteOwnerType } from '@/types';

import Notes from '@/components/Notes/Notes';

const PrivateNotes = () => {
  const { isUserLoading } = useCurrentUser();
  const { selectedUser, isViewLoading } = useView();

  if (isViewLoading || isUserLoading || !selectedUser.id) return null;

  return (
    <div className="h-screen w-full items-center">
      <div className="cement-300 flex h-full w-full flex-1 flex-col">
        <div className="flex-1 bg-sage-100 p-0 md:p-2">
          <div className="container mx-auto max-w-screen-lg p-4">
            <div className="flex w-full flex-1 flex-col">
              <Notes
                limit={TEN}
                owneableId={selectedUser.id}
                owneableType={NoteOwnerType.USER}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivateNotes;
