import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { getSurveyTemplate } from '@/api';
import { useCurrentUser, useView } from '@/hooks';

import type { SurveyTemplate } from '@/types';

export const useSurveyTemplate = () => {
  const { templateId = '' } = useParams();
  const { isUserLoading } = useCurrentUser();
  const { selectedUser, host, isViewLoading } = useView();

  const {
    data: surveyTemplate = {} as SurveyTemplate,
    isLoading: isSurveyTemplateLoading,
    refetch: refetchSurveyTemplate,
  } = useQuery(
    ['surveyTemplate', templateId],
    () => getSurveyTemplate(host, templateId, selectedUser.sub),
    { enabled: !isUserLoading && !isViewLoading },
  );

  return {
    isSurveyTemplateLoading,
    refetchSurveyTemplate,
    surveyTemplate,
  };
};
