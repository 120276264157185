import { useContext, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { getSessionFromFeedback } from '@/api';
import { AppContext } from '@/context';
import { AppMessageSeverities } from '@/types';

const SurveyRedirect = () => {
  const { feedbackId } = useParams();
  const [searchParams] = useSearchParams();
  const { setAppMessage } = useContext(AppContext);

  const feedbackType = searchParams.get('type') ?? '';
  const navigate = useNavigate();

  useEffect(() => {
    const getSessionFromFeedack = async (id: string) => {
      try {
        const data = await getSessionFromFeedback(id);
        navigate(`/sessions/${data.id}?type=${feedbackType}`);
      } catch (error) {
        console.error('Error getting session from feedback', error);
        setAppMessage({
          message:
            'Error getting session from feedback. Please try again later.',
          open: true,
          severity: AppMessageSeverities.Error,
        });
        navigate('/sessions/past');
      }
    };
    if (feedbackId) {
      getSessionFromFeedack(feedbackId);
    }
  }, [feedbackId]);

  return null;
};
export default SurveyRedirect;
