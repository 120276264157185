import { useLocation, useParams } from 'react-router-dom';

import { COMPANY_MEMBER_HEADERS } from '@/constants';
import { useCompanyMembers, useCompanySessions } from '@/hooks';
import { TableBody } from '@/ui';

import Empty from './Empty';
import Loading from './Loading';
import Row from './Row';

const List = () => {
  const { companyId = '' } = useParams();
  const { pathname } = useLocation();

  const isPastMembers = pathname.includes('past');

  // TODO: Update and paginate company management
  const { data: members = [], isLoading } = useCompanyMembers(
    companyId,
    !isPastMembers,
  );

  // Ideally this would happen in the Row/SessionColumn, and fetch count of sessions for each user
  // However, since member management is not paginated, this could trigger enough calls
  // to choke the browser. So we do it here, fetch all and send just what we need
  const { data: sessions = {}, isLoading: isLoadingSessions } =
    useCompanySessions(companyId);

  if (isLoading || isLoadingSessions) {
    return <Loading columns={COMPANY_MEMBER_HEADERS.length} />;
  }

  if (!members.length) {
    return <Empty columns={COMPANY_MEMBER_HEADERS.length} />;
  }

  return (
    <TableBody>
      {members.map((member) => (
        <Row
          key={`${member.id}-company-member`}
          sessionCount={sessions[member.id]}
          user={member}
        />
      ))}
    </TableBody>
  );
};

export default List;
