import { CardContent } from '@mui/material';
import { useState } from 'react';

import { ONE, ZERO } from '@/constants';
import { useCreateNote, useNotes } from '@/hooks';
import { Button, Card, Divider, Pagination, TextField, Typography } from '@/ui';

import Note from './Note';
import NotesLoading from './NotesLoading';

import type { NoteOwnerType, Note as NoteType } from '@/types';
import type { ChangeEvent, FC } from 'react';

const Notes: FC<{
  hasHover?: boolean;
  limit?: number;
  owneableId: string;
  owneableType: NoteOwnerType;
  seeAllNotes?: () => void;
  showPagination?: boolean;
}> = ({
  limit = 10,
  hasHover = false,
  owneableId,
  owneableType,
  seeAllNotes,
  showPagination = true,
}) => {
  const [page, setPage] = useState<number>(ONE);
  const onPageChange = (_: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  const [note, setNote] = useState('');
  const [saving, setSaving] = useState(false);
  const {
    data = { items: [] as NoteType[], pages: 1, total: 0 },
    isNotesLoading,
    refetchNotes,
  } = useNotes(owneableId, page, limit);
  const createNote = useCreateNote();

  const onAddNote = () => {
    setSaving(true);
    createNote.mutate(
      {
        owneableId,
        owneableType,
        value: note,
      },
      {
        onSettled: () => {
          setNote('');
          setSaving(false);
          refetchNotes();
        },
      },
    );
  };

  const onChange = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setNote(value);
  };

  return (
    <Card>
      <CardContent className="flex flex-col p-6">
        <Typography className="mb-4" variant="subtitle1">
          Notes
        </Typography>

        <div className="flex h-full flex-col gap-4">
          <TextField
            label="Add note"
            minRows={5}
            value={note || ''}
            multiline
            onChange={onChange}
          />

          <div className="flex justify-end">
            <Button
              className="w-full md:w-32"
              disabled={!note.trim()}
              loading={saving}
              variant="contained"
              onClick={onAddNote}
            >
              Add Note
            </Button>
          </div>
          {isNotesLoading && <NotesLoading />}

          {!isNotesLoading && !!data.items?.length && (
            <ul className="mt-6 h-full p-0">
              {data.items.map((n: NoteType, i: number) => (
                <div key={`notes-${n.id}`}>
                  <Note
                    hasHover={hasHover}
                    note={n}
                    refetchNotes={refetchNotes}
                  />
                  {i !== data.items.length - ONE && (
                    <Divider className="-mx-6" />
                  )}
                </div>
              ))}
            </ul>
          )}

          {data?.pages > ONE && showPagination && (
            <Pagination
              count={data?.pages ?? ZERO}
              page={page}
              onChange={onPageChange}
            />
          )}

          {seeAllNotes && (
            <Button
              className="ml-auto w-full md:w-auto"
              variant="outlined"
              onClick={seeAllNotes}
            >
              See all notes
            </Button>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default Notes;
