import mentoApi from './mentoApi';

import type { SessionFeedback } from '@/types';

export const createFeedback = async (
  data: Pick<SessionFeedback, 'category' | 'content' | 'sessionId'>,
) => (await mentoApi.post('/session_feedback', data)).data;

export const updateFeedback = async (
  id: string,
  data: Pick<SessionFeedback, 'category' | 'content'>,
) => (await mentoApi.put(`/session_feedback/${id}`, data)).data;

// supports rate your session legacy emails
export const getSessionFromFeedback = async (feedbackId: string) =>
  (
    await fetch(
      `${import.meta.env.VITE_CLIENT_BACKEND_URL}/api/feedback/${feedbackId}`,
    )
  ).json();

export const sendAppFeedback = async (
  host: string,
  text: string,
  typeOfFeedback: string,
): Promise<void> =>
  (
    await fetch(`${host}/api/send-feedback`, {
      body: JSON.stringify({
        text,
        typeOfFeedback,
      }),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    })
  ).json();
