import { useQuery } from '@tanstack/react-query';
import { useLocation, useParams } from 'react-router-dom';

import { getUser } from '@/api';

import type { User } from '@/types';
import type { AxiosError } from 'axios';

export const useView = () => {
  const location = useLocation();
  const isCoach = !!location.pathname.match('/coach');
  const isClient = !isCoach;
  const { id = '' } = useParams();

  // host will be removed once we get proper backend
  const host = isCoach
    ? import.meta.env.VITE_COACH_BACKEND_URL
    : import.meta.env.VITE_CLIENT_BACKEND_URL;

  const isOverview = isCoach && !!id;

  const {
    data: selectedUser = {} as User,
    isLoading,
    refetch: refetchSelectedUser,
  } = useQuery<User, AxiosError>(['selectedUser', id], () => getUser(id), {
    enabled: !!id,
    retry: false,
  });

  return {
    clientId: id,
    host,
    isClient,
    isCoach,
    isOverview,
    isViewLoading: !!id && isLoading,
    refetchSelectedUser,
    selectedUser,
  };
};
