import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import { useCurrentUser } from '@/hooks';
import { Avatar, Icon, TableCell, TableRow, Typography } from '@/ui';
import { capitalize } from '@/utils';

import CoachChip from './CoachChip';

import type { SessionCount, User } from '@/types';
import type { FC } from 'react';

import SessionColumn from '@/components/Company/Members/List/SessionColumn';

interface RowProps {
  sessionCount: SessionCount;
  user: User;
}

const Row: FC<RowProps> = ({ sessionCount, user }) => {
  const { user: currentUser } = useCurrentUser();
  const navigate = useNavigate();

  // TODO: Clean this up when we either get rid of the teams legacy multi growth plan
  // or truly handle multiple growth plans.
  const hasStartedGrowthPlan = user.growthPlans[0]?.started || false;

  const insightStatus = capitalize(
    user.insightsStatus?.replace('_', ' ') || '',
  );

  const handleRowClick = () => navigate(`/coach/members/${user.id}`);

  return (
    <TableRow
      className="cursor-pointer"
      hover={currentUser.superAdmin}
      onClick={handleRowClick}
    >
      <TableCell>
        <div className="flex items-center gap-2">
          <Avatar
            firstName={user.firstName}
            lastName={user.lastName}
            src={user.avatarUrl ?? ''}
          />
          <div>
            <Typography variant="subtitle1">
              {user.firstName} {user.lastName}
            </Typography>
            <Typography color="text.secondary" variant="caption">
              {user.profile?.title}
            </Typography>
          </div>
        </div>
      </TableCell>
      <TableCell>
        <Typography color="text.secondary" variant="caption">
          {format(Date.parse(user.createdAt), 'MMM d yyyy')}
        </Typography>
      </TableCell>
      <TableCell>
        {user.coaches.length ? (
          <div className="flex flex-col items-start gap-1">
            {user.coaches.map((coach) => (
              <CoachChip
                key={`coach-avatar-${coach.id}-${user.id}`}
                coach={coach}
              />
            ))}
          </div>
        ) : (
          <Typography color="text.secondary" variant="caption">
            No coach assigned
          </Typography>
        )}
      </TableCell>
      <TableCell className="md:table-cell">
        {hasStartedGrowthPlan ? (
          <Icon color="success">check</Icon>
        ) : (
          <Icon color="warning">close</Icon>
        )}
      </TableCell>
      <TableCell className="md:table-cell">{insightStatus}</TableCell>
      <TableCell className="hidden md:table-cell">
        <SessionColumn
          timezone={user.profile?.timezone ?? 'America/Los_Angeles'}
          {...sessionCount}
        />
      </TableCell>
    </TableRow>
  );
};

export default Row;
