import type { Client } from './client';
import type { Coach } from './coach';
import type { SurveyTemplate } from './insights';
import type { Session } from './session';
import type { User } from './user';
import type { ActionCardProps } from '@/ui';

export enum CardType {
  ADD_PROFILE_IMAGE = 'ADD_PROFILE_IMAGE',
  AI_WORKFLOW = 'AI_WORKFLOW',
  BOULDERS = 'BOULDERS',
  CAREER_CHECKIN = 'CAREER_CHECKIN',
  CREATE_PROFILE = 'CREATE_PROFILE',
  CREATE_TEAM = 'CREATE_TEAM',
  EVENT = 'EVENT',
  GET_MATCHED = 'GET_MATCHED',
  GROWTH_PLAN = 'GROWTH_PLAN',
  LEARN_ABOUT = 'LEARN_ABOUT',
  MATCHING_COACH = 'MATCHING_COACH',
  MEET_COACH = 'MEET_COACH',
  NEXT_SESSION = 'NEXT_SESSION',
  PEER_INSIGHT = 'PEER_INSIGHT',
  PEER_INSIGHT_AWARENESS = 'PEER_INSIGHT_AWARENESS',
  PREVIOUS_SESSION = 'PREVIOUS_SESSION',
}

export interface InlineImage {
  imageUrl?: string;
  isAvatar?: boolean;
  name: string;
}

export interface HomeCardProps extends ActionCardProps {
  client?: Client;
  coach?: Coach | User;
  ctaLabel?: string;
  ctaLink?: string;
  dismissedTill?: string;
  id: string;
  imageUrl?: string;
  isDismissable?: boolean;
  isImageInline?: boolean;
  isSessionToday?: boolean;
  onDismiss: (id: string, callback?: () => void) => void;
  peerInsight?: SurveyTemplate;
  refetch: () => void;
  session?: Session;
  type: CardType;
  user?: User;
}

export type HomeCardPropsWithoutCallbacks = Omit<
  HomeCardProps,
  'refetch' | 'onDismiss'
>;
