// TODO: putting all the client/coach apis here
// as we unify the user, most of these become one
// Also a lot are not RESTful, those should be removed
import { formatDateDDMMYYYY, identify, track } from '@/utils';

import mentoApi from './mentoApi';

import type {
  Client,
  Coach,
  CoachCompany,
  MemberCoach,
  PaginatedResponse,
  SortDirection,
  User,
  UsersEngagement,
} from '@/types';

// if nothing is found returns null, return obj instead.
// TODO: Remove identify from here, we agreed API calls would only be API calls
// Also, this should probably be run as part of the AppContext set up, not on every
// api call to `/me`
export const getCurrentUser = async (): Promise<Awaited<User>> => {
  const result = await mentoApi.get('/users/me');
  if (result.data) {
    identify(result.data);
  }
  return result.data ?? {};
};

export interface UserApiOptions {
  coach?: boolean;
  filter?: string;
  limit?: Number;
  page?: Number;
  search?: string;
  sortBy?: string;
  sortDirection?: SortDirection;
  userId?: string; // TODO: Change param name to be more intuitive. Right now this is passed to limit coaches to coaches for a user
  view?: string;
}
export const getUsers = async (
  params: UserApiOptions,
): Promise<PaginatedResponse<User>> =>
  (await mentoApi.get('/users', { params })).data;

/* Client management */

// TODO: This API makes no sense, wherever it is used, should be replaced
// by 'getClient'
// If we need the coach to strictly be assigned to the client we can do
// so in the BE, with a filter, passing the coachId in the body/query string
export const getClientForCoach = async (
  host: string,
  clientId: string,
  coachId: string,
): Promise<Client> =>
  (
    await fetch(`${host}/api/coaches/${coachId}/clients/${clientId}`, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    })
  ).json();

export const updateClient = async (
  host: string,
  clientId: string,
  updatedData: Partial<Client>,
): Promise<Client | null> =>
  (
    await fetch(`${host}/api/clients/${clientId}`, {
      body: JSON.stringify(updatedData),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
    })
  ).json();

// TODO: Why do we need skip? should it be in body?
export const activateClient = async (host: string, clientId: string) =>
  (
    await fetch(`${host}/api/clients/${clientId}?skip=true`, {
      body: JSON.stringify({
        isActive: true,
        paymentSubscriptionStatus: 'incomplete',
      }),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
    })
  ).json();

// TODO: should be PATCH not DELETE
// TODO: can be merged into single API call with activate, change to toggle
export const deactivateClient = async (host: string, clientId: string) =>
  (
    await fetch(`${host}/api/clients/${clientId}`, {
      credentials: 'include',
      method: 'DELETE',
    })
  ).json();

/* Coach management */
// TODO: These two APIs are inconsistent, coach side
// is paginated for the coach list
// Client is not paginated

// TODO: This API is duplicated in client and coach, but both return
// completely different data
// Also, Remove the type, was only created for this specific call
export const getMemberCoaches = async (
  host: string,
  id: string,
): Promise<MemberCoach> =>
  (
    await fetch(`${host}/api/clients/${id}/coaches`, {
      credentials: 'include',
    })
  ).json();

export const getCoach = async (host: string, coachId: string) =>
  (
    await fetch(`${host}/api/coaches/${coachId}`, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  ).json();

export const updateCoach = async (
  host: string,
  coachId: string,
  updatedData: Partial<Coach>,
) =>
  (
    await fetch(`${host}/api/coaches/${coachId}`, {
      body: JSON.stringify(updatedData),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
    })
  ).json();

export const updateCoaches = async (
  host: string,
  clientId: string,
  coachId: string,
  send: boolean,
) =>
  (
    await fetch(`${host}/api/clients/${clientId}/coaches/${coachId}`, {
      body: JSON.stringify({ sendEmail: send }),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'PUT',
    })
  ).json();

// This is " set coach to inactive"
// TODO: Should be a PATCH and have a much better name
export const removeCoach = async (host: string, coachId: string) =>
  (
    await fetch(`${host}/api/coaches/${coachId}`, {
      // TODO update api so we dont have to pass in should delete
      body: JSON.stringify({
        shouldDelete: true,
      }),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    })
  ).json();

// This is "remove coach from system"
export const deleteCoach = async (
  host: string,
  clientId: string,
  coachId: string,
) => {
  const response = await fetch(
    `${host}/api/clients/${clientId}/coaches/${coachId}`,
    {
      credentials: 'include',
      method: 'DELETE',
    },
  );
  const data = await response.json();
  return data.coaches ?? [];
};

export const setCoachBannerDismissed = async (host: string, coach: Coach) => {
  if (!coach.id) return;

  try {
    track('Dismissed Banner', { campaign: 'Peer Insights July 2023' });
    const additionalInfo = coach.additionalInfo ?? {};

    await fetch(`${host}/api/coaches/${coach.id}`, {
      body: JSON.stringify({
        additionalInfo: {
          ...additionalInfo,
          coachBannerDismissed: true,
        },
      }),
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      method: 'PATCH',
    });
  } catch (e) {
    console.error(e);
  }
};
export const setCoachBannerSeenAt = async (host: string, coach: Coach) => {
  if (!coach.id) return;

  try {
    track('Viewed banner', { campaign: 'Peer Insights July 2023' });
    const additionalInfo = coach.additionalInfo ?? {};

    await fetch(`${host}/api/coaches/${coach.id}`, {
      body: JSON.stringify({
        additionalInfo: {
          ...additionalInfo,
          coachBannerSeenAt: new Date(),
        },
      }),
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      method: 'PATCH',
    });
  } catch (e) {
    console.error(e);
  }
};

/* Coach Client relationship */
export const getCoachClients = async (host: string, coachId: string) =>
  (
    await fetch(`${host}/api/coaches/${coachId}/clients`, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  ).json();

// TODO: This API call is insanely complex
// we definitively need to abstract things away
// and move this out of /api, we should only have api calls here directly
// This really seems like the sort of thing the BE should do, not FE
export const updateCoachCompanies = async (
  host: string,
  coachId: string,
  existingCompanies: CoachCompany[] = [],
  updatedCompanies: CoachCompany[] = [],
) => {
  const toRemove: CoachCompany[] = [];
  existingCompanies.forEach((company) => {
    if (!updatedCompanies.some((c) => c.id === company.id)) {
      toRemove.push(company);
    }
  });

  const removeAll: Promise<Response>[] = [];
  toRemove.forEach((remove) => {
    removeAll.push(
      fetch(`${host}/api/coaches/${coachId}/company/${remove.id}`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'DELETE',
      }),
    );
  });

  const updateOrCreate: Promise<Response>[] = [];
  updatedCompanies.forEach((company: CoachCompany) => {
    if (company.id) {
      updateOrCreate.push(
        fetch(
          `${
            import.meta.env.VITE_COACH_BACKEND_URL
          }/api/coaches/${coachId}/company/${company.id}`,
          {
            body: JSON.stringify(company),
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
            method: 'PATCH',
          },
        ),
      );
    } else {
      updateOrCreate.push(
        fetch(
          `${
            import.meta.env.VITE_COACH_BACKEND_URL
          }/api/coaches/${coachId}/company`,
          {
            body: JSON.stringify(company),
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
            method: 'POST',
          },
        ),
      );
    }
  });

  try {
    await Promise.all([...removeAll, ...updateOrCreate]);
  } catch (e) {
    console.error(e);
  }
};

export const getUser = async (id: string) => {
  const result = await mentoApi.get(`/users/${id}`);
  return result.data;
};

export const updateUser = async (id: string, user: Partial<User>) => {
  const result = await mentoApi.put(`/users/${id}`, { user });
  return result.data;
};

export const getUsersEngagement = async (
  companyId: string,
  start?: Date,
): Promise<UsersEngagement> => {
  const startDate = start ? `${formatDateDDMMYYYY(start)}` : '';
  return (
    await mentoApi.get(
      `/users/stats?company_id=${companyId}${
        startDate ? '&start=startDate' : ''
      }`,
    )
  ).data;
};
