export const sendEmail = async (
  host: string,
  emailType: string,
  email: string,
  phone: string,
  timezone: string,
) => {
  try {
    await fetch(`${host}/api/notification`, {
      body: JSON.stringify({ email, emailType, phone, timezone }),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });
  } catch (e) {
    console.error(e);
  }
};
