import { useState } from 'react';

import { ONE, ZERO } from '@/constants';
import { useSurvey, useSurveyTemplate } from '@/hooks';
import { SurveyResponseStatus } from '@/types';
import { Paper } from '@/ui';

import Navigation from './Navigation';
import Slides from './Slides';
import Start from './Start';

import type { SummarySlide } from '@/types';
import type { FC } from 'react';

interface ViewerProps {
  isExample?: boolean;
}

const Viewer: FC<ViewerProps> = ({ isExample = false }) => {
  const [page, setPage] = useState(-ONE);
  const { survey, isSurveyLoading } = useSurvey(SurveyResponseStatus.COMPLETED);
  const { surveyTemplate, isSurveyTemplateLoading } = useSurveyTemplate();

  if (isSurveyLoading || isSurveyTemplateLoading) return null;

  const assignedParticipants = isExample
    ? surveyTemplate.exampleSurvey?.assignedParticipants
    : survey.assignedParticipants;

  const results =
    assignedParticipants
      ?.sort(
        (a, b) =>
          new Date(b.updatedAt).valueOf() - new Date(a.updatedAt).valueOf(),
      )
      ?.map(({ results: res }) => res) ?? [];

  const slideList: SummarySlide[] = [];
  surveyTemplate.surveyQuestions.forEach((question) => {
    if (question.options?.length) {
      slideList.push(question);
    } else {
      results.forEach((result) => {
        const { text } = result[question.name ?? ''] ?? {};
        if (text) {
          slideList.push({ ...question, text });
        }
      });
    }
  });

  const pages = slideList.length - ONE;
  const previousPage = () => setPage(Math.max(page - ONE, -ONE));
  const nextPage = () => setPage(Math.min(page + ONE, pages));

  if (page < ZERO) {
    return (
      <Paper
        className="mb-20 h-158 rounded-3xl border border-cement-300 p-6 pb-11 lg:p-16"
        style={{
          background:
            "url('https://mento-space.nyc3.cdn.digitaloceanspaces.com/assets%2Fsurvey-summary.png') center center no-repeat",
          backgroundSize: 'cover',
        }}
      >
        <Start
          isExample={isExample}
          name={surveyTemplate.name}
          next={nextPage}
          results={results.length}
          {...survey}
        />
      </Paper>
    );
  }

  return (
    <Paper className="mb-20 flex h-fit min-h-158 flex-col rounded-3xl border border-cement-600 bg-white p-6 pb-11 lg:p-16">
      <Slides page={page} results={results} slides={slideList} />
      <Navigation
        back={previousPage}
        next={nextPage}
        page={page}
        pages={pages}
      />
    </Paper>
  );
};

export default Viewer;
