import { SurveyStatus } from '@/types';

import type {
  Recipient,
  Survey,
  SurveyResponseStatus,
  SurveyTemplate,
} from '@/types';

export const getSurveyTemplate = async (
  host: string,
  templateId: string,
  clientSub?: string,
): Promise<SurveyTemplate> =>
  (
    await fetch(
      `${host}/api/surveyTemplates/${templateId}?clientSub=${clientSub}`,
      {
        credentials: 'include',
      },
    )
  ).json();

export const getSurveyTemplates = async (
  host: string,
  clientSub?: string,
): Promise<SurveyTemplate[]> => {
  if (clientSub) {
    return (
      await fetch(`${host}/api/clients/${clientSub}/surveyTemplates`, {
        credentials: 'include',
      })
    ).json();
  }
  return (
    await fetch(`${host}/api/surveyTemplates`, {
      credentials: 'include',
    })
  ).json();
};

export const getSurveys = async (
  host: string,
  templateId: string,
  clientSub?: string,
  status?: SurveyResponseStatus,
): Promise<Survey> =>
  (
    await fetch(
      `${host}/api/surveys?templateId=${templateId}&status=${
        status ?? ''
      }&clientSub=${clientSub ?? ''}`,
      {
        credentials: 'include',
      },
    )
  ).json();

export const createSurvey = async (
  host: string,
  surveyTemplateId: string,
  assignedParticipants: Recipient[],
) => {
  let surveyId = '';
  try {
    const response = await fetch(`${host}/api/surveys`, {
      body: JSON.stringify({ assignedParticipants, surveyTemplateId }),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });
    const { id } = await response.json();
    surveyId = id;
  } catch (e) {
    console.error(e);
  }
  return surveyId;
};
export const sendSurvey = async (
  host: string,
  surveyId: string,
  emailTemplate: string,
) => {
  try {
    await fetch(`${host}/api/surveys/${surveyId}/send`, {
      body: JSON.stringify({ emailTemplate }),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
    });
  } catch (e) {
    console.error(e);
  }
};

export const shareSurvey = async (host: string, surveyId: string) => {
  try {
    await fetch(`${host}/api/surveys/${surveyId}`, {
      body: JSON.stringify({ status: SurveyStatus.SHARED }),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
    });
  } catch (e) {
    console.error(e);
  }
};

export const updateParticipants = async (
  host: string,
  surveyId: string,
  assignedParticipants: Recipient[],
) => {
  try {
    await fetch(`${host}/api/surveys/${surveyId}`, {
      body: JSON.stringify({ assignedParticipants }),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
    });
  } catch (e) {
    console.error(e);
  }
};
