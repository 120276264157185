import { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { updateHubspotContact, updateUser, updateUserProfile } from '@/api';
import { AppContext } from '@/context';
import { useCurrentUser } from '@/hooks';
import { AppMessageSeverities } from '@/types';
import { Button, Dialog, TextField, Typography } from '@/ui';

import type { AboutForm } from '@/types';
import type { ChangeEvent, FC } from 'react';
import type { ControllerRenderProps } from 'react-hook-form';

interface RequireUserInfoProps {
  isOpen: Boolean;
}

const RequireUserInfo: FC<RequireUserInfoProps> = ({ isOpen = false }) => {
  const { user, isUserLoading, refetchUser } = useCurrentUser();
  const [saving, setSaving] = useState(false);
  const { setAppMessage } = useContext(AppContext);

  const {
    handleSubmit,
    control,
    formState: { errors, isValid } = {},
  } = useForm<AboutForm>({
    defaultValues: {
      company: user.profile?.company ?? '',
      firstName: '',
      lastName: user.lastName,
      title: user.profile?.title ?? '',
    },
    mode: 'onChange',
  });

  const onFirstNameChange =
    (field: ControllerRenderProps<AboutForm, 'firstName'>) =>
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      field.onChange(e.target.value);

  const onLastNameChange =
    (field: ControllerRenderProps<AboutForm, 'lastName'>) =>
    (e: ChangeEvent<HTMLInputElement>) =>
      field.onChange(e.target.value);

  const onTitleChange =
    (field: ControllerRenderProps<AboutForm, 'title'>) =>
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      field.onChange(e.target.value);

  const onCompanyChange =
    (field: ControllerRenderProps<AboutForm, 'company'>) =>
    (e: ChangeEvent<HTMLInputElement>) =>
      field.onChange(e.target.value);

  const onSave = handleSubmit(
    async ({ company, firstName, lastName, title }) => {
      try {
        setSaving(true);

        await Promise.allSettled([
          updateHubspotContact(user.email, {
            company,
            firstname: firstName,
            full_name: `${firstName} ${lastName}`,
            jobtitle: title,
            lastname: lastName,
          }),
          updateUser(user.id, { firstName, lastName }),
          updateUserProfile(user.id, { company, title }),
        ]);
        await refetchUser();
      } catch (e) {
        setAppMessage({
          message: 'Error saving user info',
          open: true,
          severity: AppMessageSeverities.Error,
        });
      } finally {
        setSaving(false);
      }
    },
  );

  if (isUserLoading || !isOpen) {
    return null;
  }

  return (
    <Dialog maxWidth="xs" open>
      <img
        alt="coaches-mosaic"
        src="https://mento-space.nyc3.cdn.digitaloceanspaces.com/assets/coach-mosaic-fraction.png"
      />
      <Typography className="text-center" variant="h4">
        Before we start, tell us <br /> about yourself!
      </Typography>
      <form
        className="flex flex-col space-y-4 p-8"
        id="user-info-missing"
        onSubmit={onSave}
      >
        <Controller
          control={control}
          name="firstName"
          render={({ field }) => (
            <TextField
              error={!!errors?.firstName}
              helperText={errors?.firstName && 'First name is required'}
              label="First name"
              value={field.value}
              onChange={onFirstNameChange(field)}
            />
          )}
          rules={{ required: true }}
        />
        <Controller
          control={control}
          name="lastName"
          render={({ field }) => (
            <TextField
              error={!!errors?.lastName}
              helperText={errors?.lastName && 'Last name is required'}
              label="Last name"
              value={field.value}
              onChange={onLastNameChange(field)}
            />
          )}
          rules={{ required: true }}
        />
        <Controller
          control={control}
          name="title"
          render={({ field }) => (
            <TextField
              error={!!errors?.title}
              helperText={errors?.title && 'Title is required'}
              label="Current or recent job title"
              value={field.value}
              onChange={onTitleChange(field)}
            />
          )}
          rules={{ required: true }}
        />
        <Controller
          control={control}
          name="company"
          render={({ field }) => (
            <TextField
              error={!!errors?.company}
              helperText={errors?.company && 'Company is required'}
              label={`Current${
                user.profile?.company ? ' or recent' : ''
              } company`}
              value={field.value}
              onChange={onCompanyChange(field)}
            />
          )}
          rules={{ required: true }}
        />
      </form>
      <div className="mx-auto pb-8">
        <Button
          className="px-20"
          color="primary"
          disabled={!isValid}
          form="user-info-missing"
          loading={saving}
          type="submit"
          variant="contained"
        >
          Continue
        </Button>
      </div>
    </Dialog>
  );
};

export default RequireUserInfo;
