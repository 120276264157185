// For info on how to create dashboard cards refer to docs/DASHBOARD_CARDS.md
import { useCurrentUser, useHomeCards, useView } from '@/hooks';
import { Skeleton } from '@/ui';

import HomeCard from './HomeCard';

const Cards = () => {
  const { host } = useView();
  const { user, isUserLoading } = useCurrentUser();
  const {
    cards,
    isLoading: isCardsLoading,
    refetch,
    onDismiss,
  } = useHomeCards({
    enabled: !!user.id,
    host,
  });

  if (isCardsLoading || isUserLoading) {
    return (
      <div className="mt-4 md:mt-0">
        <div className="flex min-h-104 gap-4 overflow-x-auto pb-4 pl-4 first:pl-2 last:pr-12">
          <Skeleton
            animation="wave"
            className="w-64 bg-cement-400 opacity-25"
            height={414}
            variant="rounded"
          />
          <Skeleton
            animation="wave"
            className="w-64 bg-cement-400 opacity-25"
            height={414}
            variant="rounded"
          />
          <Skeleton
            animation="wave"
            className="w-64 bg-cement-400 opacity-25"
            height={414}
            variant="rounded"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="mt-4 md:mt-0">
      <div
        className="flex min-h-104 gap-4 overflow-x-auto pb-4 pl-4 first:pl-2 last:pr-12"
        data-testid="card-holder"
      >
        {cards.map((card) => (
          <HomeCard
            key={card.id}
            {...card}
            refetch={refetch}
            onDismiss={onDismiss}
          />
        ))}
      </div>
    </div>
  );
};

export default Cards;
