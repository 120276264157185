import { formatISO, parseISO } from 'date-fns';
import { useState } from 'react';
import { useController, useForm } from 'react-hook-form';

import { ZERO } from '@/constants';
import { useCreateEvent, useGetEvents, useUpdateEvent, useView } from '@/hooks';
import { UploadType } from '@/types';
import {
  ActionCard,
  Button,
  DatePicker,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  TextField,
  Typography,
} from '@/ui';
import { getEventCardProps, isValidWebsite } from '@/utils';

import SwitchToggle from './switchToggle';

import type { Event } from '@/types';
import type { FC } from 'react';

import UploadImage from '@/components/Upload';

interface EditEventDialogProps {
  currentEvent?: Event;
  isOpen: boolean;
  onClose: () => void;
}

const EditEventDialog: FC<EditEventDialogProps> = ({
  isOpen,
  onClose,
  currentEvent = {},
}) => {
  // Constants
  const MAX_TITLE_LENGTH = 40;
  const MAX_BUTTON_LENGTH = 30;

  // Form stuff
  const {
    control,
    formState: { isValid, isDirty },
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
  } = useForm<Event>({
    defaultValues: {
      body: '',
      ctaLabel: '',
      ctaLink: '',
      endedAt: null,
      imageUrl: '',
      isDarkTheme: false,
      isDismissable: false,
      isImageInline: false,
      isPinned: false,
      label: 'Event',
      startedAt: null,
      title: '',
      ...(currentEvent as Omit<Partial<Event>, 'clients'>),
    },
    mode: 'onBlur',
  });
  const { field: label } = useController({ control, name: 'label' });
  const { field: title, fieldState: titleState } = useController({
    control,
    name: 'title',
    rules: { maxLength: MAX_TITLE_LENGTH, required: true },
  });
  const { field: body } = useController({ control, name: 'body' });
  const { field: ctaLabel, fieldState: ctaLabelState } = useController({
    control,
    name: 'ctaLabel',
    rules: { maxLength: MAX_BUTTON_LENGTH },
  });
  const { field: ctaLink, fieldState: ctaLinkState } = useController({
    control,
    name: 'ctaLink',
    rules: {
      validate: (value) => (value ? isValidWebsite(value, true) : true),
    },
  });
  const { field: isDarkTheme } = useController({
    control,
    name: 'isDarkTheme',
  });
  const { field: isImageInline } = useController({
    control,
    name: 'isImageInline',
  });
  const { field: startedAt } = useController({ control, name: 'startedAt' });
  const { field: endedAt } = useController({ control, name: 'endedAt' });
  const { field: isDismissable } = useController({
    control,
    name: 'isDismissable',
  });
  const { field: isPinned } = useController({ control, name: 'isPinned' });
  const event = watch();
  register('imageUrl');

  // State
  const [isPublishNow, setIsPublishNow] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);
  const { host } = useView();
  const { refetch: refetchEvents } = useGetEvents({ host });
  const getLengthHelperText = (max: number, current?: number) =>
    `${max - (current || ZERO)} characters left`;
  const getDateValue = (date?: string | null) => (date ? parseISO(date) : null);

  // Handlers
  const handleClose = () => {
    reset();
    onClose();
  };
  const onUploadPhoto = () => setUploadingImage(true);
  const onClearPhoto = () => setValue('imageUrl', undefined);
  const onUploadImageChanged = (url?: string) => {
    setUploadingImage(false);
    setValue('imageUrl', url);
  };
  const onPublishNow = () => {
    setIsPublishNow(!isPublishNow);
  };

  // Network Actions
  const createEvent = useCreateEvent();
  const updateEvent = useUpdateEvent();
  const callbacks = {
    onSettled: handleClose,
    onSuccess: () => refetchEvents(),
  };
  const postEvent = (data: Event) =>
    createEvent.mutate({ event: data, host }, callbacks);
  const patchEvent = (data: Event) =>
    updateEvent.mutate({ event: data, host }, callbacks);
  const onSubmit = handleSubmit((data) => {
    const publishNowData = {
      ...(isPublishNow ? { startedAt: formatISO(new Date()) } : {}),
    };
    if (currentEvent.id) {
      patchEvent({ ...data, ...publishNowData, id: currentEvent.id });
    } else {
      postEvent({ ...data, ...publishNowData });
    }
  });

  return (
    <Dialog maxWidth="md" open={isOpen} scroll="paper" fullWidth>
      <DialogContent>
        <form id="new-edit-event-card" onSubmit={onSubmit}>
          <div className="grid grid-cols-5 gap-8">
            <div className="col-span-5 mb-4 md:col-span-2 md:mb-0">
              <Typography className="mb-4 mt-3" variant="h4">
                Action card creator
              </Typography>
              <ActionCard
                {...getEventCardProps(event)}
                cardMedia={
                  event.isImageInline ? (
                    <img alt="" src={event.imageUrl} />
                  ) : undefined
                }
                status={undefined}
              />

              <div className="mt-4 flex gap-2 pr-11">
                <Button
                  className="whitespace-nowrap"
                  variant="contained"
                  fullWidth
                  onClick={onUploadPhoto}
                >
                  Upload photo
                </Button>

                {!!event.imageUrl && (
                  <Button
                    className="whitespace-nowrap"
                    variant="outlined"
                    fullWidth
                    onClick={onClearPhoto}
                  >
                    Clear image
                  </Button>
                )}
              </div>
            </div>
            <div className="col-span-5 mt-6 flex flex-col gap-4 md:col-span-3">
              <TextField label="Label" {...label} />
              <TextField
                error={titleState.invalid}
                helperText={getLengthHelperText(
                  MAX_TITLE_LENGTH,
                  event.title.length,
                )}
                inputProps={{ maxLength: MAX_TITLE_LENGTH }}
                label="Title"
                {...title}
              />
              <TextField label="Body" maxRows={3} multiline {...body} />
              <TextField
                error={ctaLabelState.invalid}
                helperText={getLengthHelperText(
                  MAX_BUTTON_LENGTH,
                  event.ctaLabel?.length,
                )}
                inputProps={{ maxLength: MAX_BUTTON_LENGTH }}
                label="Button"
                {...ctaLabel}
              />
              <TextField
                error={ctaLinkState.invalid}
                helperText="Url must start with https://"
                label="Button url"
                {...ctaLink}
              />
              <SwitchToggle
                icon="dark_mode"
                title="Enable dark theme"
                {...isDarkTheme}
                checked={isDarkTheme.value}
              />
              <SwitchToggle
                icon="imagesmode"
                title="Use the image inline"
                {...isImageInline}
                checked={isImageInline.value}
              />
            </div>
          </div>
          <Divider className="my-8" />
          <div className="col-span-5 grid-cols-5 gap-8 md:grid">
            <div className="col-span-5 md:col-span-2">
              <Typography className="mb-4" variant="h4">
                Rules & audience
              </Typography>
              <Typography color="text.secondary">
                Define when the card is seen and by whom.
              </Typography>
            </div>
            <div className="col-span-3 flex flex-col gap-4">
              <SwitchToggle
                checked={isPublishNow}
                icon="send"
                title="Publish now"
                onChange={onPublishNow}
              />

              <div className="flex justify-between gap-4">
                <DatePicker
                  label="Start date"
                  {...startedAt}
                  value={getDateValue(startedAt.value)}
                />
                <DatePicker
                  label="End date"
                  {...endedAt}
                  value={getDateValue(endedAt.value)}
                />
              </div>

              <SwitchToggle
                icon="disabled_by_default"
                title="Allow to be dismissed"
                {...isDismissable}
                checked={isDismissable.value}
              />
              <SwitchToggle
                icon="push_pin"
                title="Pin to dashboard (promote)"
                {...isPinned}
                checked={isPinned.value}
              />
            </div>
          </div>
        </form>

        {!!uploadingImage && (
          <UploadImage
            filename={currentEvent.id}
            type={UploadType.EVENT}
            onClose={onUploadImageChanged}
          />
        )}
      </DialogContent>

      <DialogActions>
        <Button color="primary" variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={!isValid || (!!currentEvent.id && !isDirty)}
          form="new-edit-event-card"
          loading={createEvent.isLoading || updateEvent.isLoading}
          type="submit"
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditEventDialog;
