import { useQuery } from '@tanstack/react-query';

import { getSurveyTemplates } from '@/api';
import { useCurrentUser, useView } from '@/hooks';

import Card from './Card';

import type { SurveyTemplate } from '@/types';

const TemplateCards = () => {
  const { user } = useCurrentUser();
  const { host, selectedUser, isOverview } = useView();

  const client = isOverview ? selectedUser : user;

  const { data: templates } = useQuery(
    ['surveyTemplates', client.sub],
    async (): Promise<SurveyTemplate[]> =>
      getSurveyTemplates(host, isOverview ? client.sub : undefined),
    { enabled: !!client.id, refetchOnMount: 'always' },
  );

  if (!client.id || !templates) return null;

  const coachName = client.coaches[0]?.fullName ?? '';

  return (
    <div className="flex flex-col space-y-4">
      {templates.map((template) => (
        <Card
          key={`survey-template-card-${template.id}`}
          coach={coachName}
          {...template}
        />
      ))}
    </div>
  );
};

export default TemplateCards;
