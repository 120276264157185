// TODO: Event is too generic and overlaps other things
// We should update to notifications or something similar
import type { Event } from '@/types';

// Get Events
export type GetEventsParams = {
  host: string;
};
export const getEvents = async ({ host }: GetEventsParams) => {
  try {
    return await (
      await fetch(`${host}/api/events`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      })
    ).json();
  } catch (e) {
    console.error(e);
  }

  return [];
};

// Post Event
export type PostEventParams = {
  event: Event;
  host: string;
};
export const postEvent = async ({
  host,
  event,
}: PostEventParams): Promise<Event | null> => {
  try {
    return await (
      await fetch(`${host}/api/events`, {
        body: JSON.stringify(event),
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })
    ).json();
  } catch (e) {
    console.error(e);
  }

  return null;
};

// Patch Event
export type PatchEventParams = {
  event: Partial<Event> & { dismissedTill?: string };
  host: string;
};
export const patchEvent = async ({
  host,
  event,
}: PatchEventParams): Promise<Event | null> => {
  try {
    return await (
      await fetch(`${host}/api/events/${event.id}`, {
        body: JSON.stringify(event),
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'PATCH',
      })
    ).json();
  } catch (e) {
    console.error(e);
  }

  return null;
};

export type DeleteEventParams = {
  eventId: string;
  host: string;
};
export const deleteEvent = async ({
  eventId,
  host,
}: DeleteEventParams): Promise<string | null> => {
  try {
    return await (
      await fetch(`${host}/api/events/${eventId}`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'DELETE',
      })
    ).json();
  } catch (e) {
    console.error(e);
  }

  return null;
};
