import { useEffect, useState } from 'react';

import { sendAppFeedback } from '@/api';
import { FEEDBACK } from '@/constants';
import { useView } from '@/hooks';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  TextField,
  Typography,
} from '@/ui';
import { track } from '@/utils';

import type { ChangeEvent, FC } from 'react';

interface FeedbackProps {
  onClose: () => void;
}

const Feedback: FC<FeedbackProps> = ({ onClose }) => {
  const { host } = useView();
  const [isSaving, setIsSaving] = useState(false);
  const [typeOfFeedback, setTypeOfFeedback] = useState('');
  const [text, setText] = useState('');

  useEffect(() => {
    track('Viewed App Feedback');
  }, []);

  const onFeedbackType = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => setTypeOfFeedback(e.target.value);
  const onText = (e: ChangeEvent<HTMLInputElement>) => setText(e.target.value);

  const onSendFeedback = async () => {
    setIsSaving(true);
    await sendAppFeedback(host, text, typeOfFeedback);
    setIsSaving(false);
    onClose();
  };

  const isDisabled = !typeOfFeedback.length || !text.length;

  return (
    <Dialog maxWidth="md" fullWidth open>
      <DialogContent className="flex flex-col gap-4">
        <Typography className="mb-2" variant="h4">
          Share your feedback
        </Typography>
        <TextField
          label="What does your feedback relate to?"
          value={typeOfFeedback}
          select
          onChange={onFeedbackType}
        >
          {FEEDBACK.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Tell us more"
          rows={4}
          value={text}
          multiline
          onChange={onText}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={isDisabled}
          loading={isSaving}
          variant="contained"
          onClick={onSendFeedback}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Feedback;
