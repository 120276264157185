import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { getSurveys } from '@/api';
import { useCurrentUser, useView } from '@/hooks';

import type { Survey, SurveyResponseStatus } from '@/types';

export const useSurvey = (status?: SurveyResponseStatus) => {
  const { templateId = '' } = useParams();
  const { isUserLoading } = useCurrentUser();
  const { selectedUser, host } = useView();

  const {
    data: survey = {} as Survey,
    isLoading: isSurveyLoading,
    refetch: refetchSurvey,
  } = useQuery(
    ['survey', templateId, status, selectedUser.sub],
    () => getSurveys(host, templateId, selectedUser.sub, status),
    {
      enabled: !isUserLoading && !!templateId,
    },
  );

  return {
    isSurveyLoading,
    refetchSurvey,
    survey,
  };
};
