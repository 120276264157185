// For info on how to create dashboard cards refer to docs/DASHBOARD_CARDS.md
import { useFlagBag } from '@happykit/flags/context';
import { addMonths, addWeeks, formatISO } from 'date-fns';
import { useEffect, useState } from 'react';

import { ONE, ZERO } from '@/constants';
import {
  useCurrentUser,
  useGetHome,
  useSessionsDashboard,
  useUpdateClientEvent,
  useUpdateEvent,
} from '@/hooks';
import {
  CardType,
  type Event,
  type FeatureFlags,
  type HomeCardPropsWithoutCallbacks,
} from '@/types';
import {
  getAiWorkflowCardProps,
  getClientEventsCardsProps,
  getCoachSessionsCardProps,
  getCreateTeamCardProps,
  getEventCardProps,
  getMatchedWithCoachCardProps,
  getMatchingCoachCardProps,
  track,
} from '@/utils';

export const useHomeCards = ({
  host,
  enabled = false,
}: {
  enabled?: boolean;
  host: string;
}) => {
  const { data, isLoading, refetch } = useGetHome({ host }, { enabled });
  const { data: sessionsData } = useSessionsDashboard();
  const { flags } = useFlagBag<FeatureFlags>();
  const [cards, setCards] = useState<HomeCardPropsWithoutCallbacks[]>([]);
  const { user, isUserLoading } = useCurrentUser();
  const updateClientEvent = useUpdateClientEvent();
  const updateEvent = useUpdateEvent();
  const MAX_HOME_CARDS = 5;

  const onDismiss = (id: string) => {
    const card = cards.find((i) => i.id === id);
    if (!card) {
      return;
    }
    const index = cards.indexOf(card);
    const hasBeenDismissed = !!card.dismissedTill;

    track('Dismissed dashboard action card', {
      clientId: card.client?.id,
      label: card.label,
      title: card.title,
    });
    // Optimiscally update array
    setCards([...cards.slice(ZERO, index), ...cards.slice(index + ONE)]);
    // Update card on BE
    const dismissedTill = hasBeenDismissed
      ? formatISO(addMonths(new Date(), ONE))
      : formatISO(addWeeks(new Date(), ONE));
    if (card.type === CardType.EVENT) {
      updateEvent.mutate(
        { event: { dismissedTill, id: card.id }, host },
        { onSuccess: () => refetch() },
      );
    } else {
      updateClientEvent.mutate(
        { dismissedTill, host, key: card.type },
        { onSuccess: () => refetch() },
      );
    }
  };

  const parseEvents = ({
    events = [],
    pinned,
  }: {
    events: Event[];
    pinned?: boolean;
  }) =>
    events
      .filter((e) => (pinned ? e.isPinned : !e.isPinned))
      .map((e) => ({
        ...getEventCardProps(e),
        ctaLabel: e.ctaLabel,
        ctaLink: e.ctaLink,
        imageUrl: e.imageUrl,
        isImageInline: e.isImageInline,
      }));

  useEffect(() => {
    if (!data || !sessionsData || isUserLoading) {
      return;
    }

    const aiWorkflowPrompt = flags?.ai_workflows
      ? [getAiWorkflowCardProps()]
      : [];

    const matchingCoach = !user.coaches.length
      ? [getMatchingCoachCardProps()]
      : [];

    const createTeam =
      !flags?.teams || user.hasTeams ? [] : [getCreateTeamCardProps()];

    const getMatched = !user.userAssesment?.complete
      ? [getMatchedWithCoachCardProps()]
      : [];

    const coachesSessions = user.coaches.length
      ? getCoachSessionsCardProps({
          sessionsData,
          user,
        })
      : [];
    const clientEvents = getClientEventsCardsProps(
      data.clientEvents,
      data.peerInsight,
      data.client,
    );

    setCards(() => [
      ...aiWorkflowPrompt,
      ...parseEvents({ events: data.events, pinned: true }),
      ...matchingCoach,
      ...coachesSessions,
      ...clientEvents,
      ...createTeam,
      ...getMatched,
      ...parseEvents({ events: data.events }),
    ]);
  }, [data, sessionsData, isUserLoading]);

  return {
    cards: cards.slice(ZERO, MAX_HOME_CARDS),
    isLoading,
    onDismiss,
    refetch,
  };
};
