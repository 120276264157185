/**
 * IMPORTANT:
 * This file is purposefuly miss-named. It should be `clientEvent.ts`.
 * However, this name fails to load on vite for some reason.
 *
 * For whatever reason, any file prefixed with the word `client` fails
 * to load, so this is a weird thing I don't have time to check.
 *
 * Just know not to rename this to `clientEvent.ts`.
 * */
import type { CardType } from '@/types';

// TODO: Why is this under the `home` path?
// we need to seriously refactor most of this card/event stuff
export type PatchClientEventParams = {
  dismissedTill?: string;
  host: string;
  key: CardType;
  viewedAt?: string;
};
export const patchClientEvent = async ({
  host,
  key,
  viewedAt,
  dismissedTill,
}: PatchClientEventParams): Promise<undefined> => {
  try {
    await fetch(`${host}/api/home/cards/${key}`, {
      body: JSON.stringify({
        dismissedTill,
        viewedAt,
      }),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
    });
  } catch (e) {
    console.error(e);
  }
};
