export const QUERY_KEY_EVENTS = 'events';
export const QUERY_KEY_HOME = 'home';
export const QUERY_KEY_COACH_HOME = 'coach-home';

export const QUERY_KEY_GROWTH_PLANS = 'growth-plans';
export const QUERY_KEY_MILESTONES = 'milestones';
export const QUERY_KEY_MILESTONE_STEPS = 'milestone-steps';
export const QUERY_KEY_MILESTONE_SUGGESTED_STEPS = 'milestone-suggested-steps';

export const QUERY_KEY_THREADS = 'ai/threads';

export const QUERY_KEY_DOCUMENTS = 'documents';
export const QUERY_KEY_DOCUMENT = 'document';

export const QUERY_KEY_TEMPLATES = 'templates';

export const QUERY_KEY_MEMBERS_MINIMAL = 'members-share';
export const QUERY_KEY_USERS = 'users';

export const QUERY_KEY_WORKFLOWS = 'workflows';
export const QUERY_KEY_WORKFLOW = 'workflow';

export const QUERY_KEY_SESSIONS = 'sessions';

export const QUERY_KEY_COMPANIES = 'companies';
export const QUERY_KEY_COMPANY = 'company';

export const QUERY_KEY_MESSAGE_THREADS = 'message-threads';
