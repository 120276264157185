import { useQuery } from '@tanstack/react-query';

import { fetchHome } from '@/api';
import { MILLISECONDS_IN_HOUR, QUERY_KEY_HOME } from '@/constants';

import type { GetHomeParams } from '@/api';
import type { ClientHome } from '@/types';

export const useGetHome = (params: GetHomeParams, { enabled = false }) =>
  useQuery<ClientHome, Error>({
    enabled,
    queryFn: () => fetchHome(params),
    queryKey: [QUERY_KEY_HOME],
    refetchInterval: MILLISECONDS_IN_HOUR,
    staleTime: 0,
  });
