import { getTimeZones } from '@vvo/tzdb';
import { MuiTelInput } from 'mui-tel-input';
import { useMemo, useState } from 'react';

import { sendEmail } from '@/api';
import { EmailTypes } from '@/constants';
import {
  Button,
  MenuItem,
  PageHeaderLayout,
  Select,
  TextField,
  Typography,
} from '@/ui';

import type { SelectChangeEvent } from '@mui/material';

const Notifications = () => {
  const [timezone, setTimezone] = useState('');
  const [emailType, setEmailType] = useState(
    EmailTypes.SEND_ADDED_CLIENT_TO_COACH,
  );
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);

  const timezones = useMemo(
    () =>
      getTimeZones()
        .filter(
          ({ continentCode }) =>
            continentCode === 'NA' || continentCode === 'EU',
        )
        .map(({ currentTimeFormat, name, mainCities }) => {
          const [time] = currentTimeFormat.split(' ');

          return {
            label: `(GMT${time}) ${mainCities[0]}`,
            value: name,
          };
        }),
    [],
  );

  const types = Object.values(EmailTypes).map((value) => ({
    label: `${value}`,
    value,
  }));

  const onTimezoneChange = (event: SelectChangeEvent) => {
    setTimezone(event.target.value);
  };

  const onEmailTypeChange = (event: SelectChangeEvent) => {
    setEmailType(event.target.value as EmailTypes);
  };

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  // Inputs are not consistent in their typing, using empty string to avoid issues
  const onChangePhone = (value = '') => setPhone(value);

  const onClick = async () => {
    setLoading(true);
    await sendEmail(
      import.meta.env.VITE_COACH_BACKEND_URL,
      emailType,
      email,
      phone,
      timezone,
    );
    setLoading(false);
  };

  return (
    <div className="flex max-w-screen-sm flex-col gap-4 p-4">
      <PageHeaderLayout title="Notifications" />

      <Typography className="my-1.5" variant="subtitle1">
        Make sure to use an @mento.co email.
        <br />
        Need to provide at least email or phone.
        <br />
        To send all emails, pick &quot;*&quot; as the email type.
      </Typography>
      <Select
        label="Email Type"
        placeholder="Email Type"
        value={emailType}
        onChange={onEmailTypeChange}
      >
        {types.map((t) => (
          <MenuItem key={t.value} value={t.value}>
            {t.label}
          </MenuItem>
        ))}
      </Select>
      <TextField
        label="Email"
        placeholder="Email"
        value={email}
        variant="outlined"
        onChange={onChangeEmail}
      />
      <MuiTelInput placeholder="Phone" value={phone} onChange={onChangePhone} />

      <Select
        label="Timezone"
        placeholder="Timezone"
        value={timezone}
        onChange={onTimezoneChange}
      >
        {timezones.map((t) => (
          <MenuItem key={t.value} value={t.value}>
            {t.label}
          </MenuItem>
        ))}
      </Select>

      <Button
        disabled={(!email && !phone) || loading}
        variant="contained"
        onClick={onClick}
      >
        Send
      </Button>
    </div>
  );
};

export default Notifications;
