import { useFlagBag } from '@happykit/flags/context';
import clsx from 'clsx';
import { type FC, useEffect } from 'react';

import { stripeCheckout, updateHubspotContact } from '@/api';
import { type FeatureFlags, PlanTypes, type Price, type User } from '@/types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  Icon,
  IconButton,
  Typography,
} from '@/ui';
import { getStripe, track } from '@/utils';

import PlanFeature from './PlanFeature';

import IconExpandMore from '@/ui/components/Icon/icons/expand_more';

interface CardProps {
  price: Price;
  user: User;
}

const Card: FC<CardProps> = ({ user, price }) => {
  const { flags } = useFlagBag<FeatureFlags>();
  const {
    amount = 0,
    pill = '',
    sub,
    title,
    type,
    feature,
    additionalFeatures,
  } = price;

  const isExec = type === PlanTypes.exec;

  useEffect(() => {
    if (flags?.hide_onboarding_plan_details !== undefined) {
      track(
        `Hide onboarding plan details: ${flags.hide_onboarding_plan_details}`,
      );
    }
  }, [flags?.hide_onboarding_plan_details]);

  const onClick = async () => {
    track(`Click ${title} Payment Onboarding`);

    try {
      const successUrl = `${
        import.meta.env.VITE_APP_URL
      }/get-started/membership-paid`;
      const cancelUrl = `${
        import.meta.env.VITE_APP_URL
      }/get-started/membership-select`;
      const { sessionId, customerId } = await stripeCheckout(
        price,
        user,
        successUrl,
        cancelUrl,
      );
      await updateHubspotContact(user.email, {
        stripe_customer_id: customerId,
      });

      const stripe = await getStripe();
      // TODO: What should happen if we can't get stripe? Display error?
      await stripe?.redirectToCheckout({ sessionId });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      className={clsx(
        'mt-6 flex max-w-full rounded-xl p-4 lg:ml-8 lg:mt-0 lg:max-w-xs lg:flex-col lg:p-8',
        {
          'bg-sage-100': !isExec,
          'bg-sky-100': isExec,
        },
      )}
    >
      <div className="w-full">
        <div className="flex flex-col gap-2 lg:block">
          <Typography
            className={clsx({
              'md:tracking-wide': !isExec,
            })}
            variant="h4"
          >
            {title}
          </Typography>

          <Chip
            className="mt-2 self-baseline"
            color="info"
            label={pill}
            size="medium"
          />
        </div>
        <div
          className={clsx('mt-2 flex items-baseline lg:!mt-6', {
            'text-sage-500': !isExec,
            'text-sky-500': isExec,
          })}
        >
          <Typography variant="h4">${amount}</Typography>
          <Typography variant="body2">&nbsp;/ month</Typography>
          <IconButton
            className={clsx('my-auto ml-auto lg:hidden', {
              'bg-sky-500 hover:bg-sky-400': isExec,
            })}
            size="large"
            variant="contained"
            onClick={onClick}
          >
            <Icon className="text-white">chevron_right</Icon>
          </IconButton>
        </div>
        {sub && (
          <Typography className="lg:mt-1.5 lg:h-6" variant="body2">
            {sub}
          </Typography>
        )}
        <Button
          className={clsx('mt-7 hidden lg:block', {
            'bg-sky-500 hover:bg-sky-600': isExec,
          })}
          variant="contained"
          fullWidth
          onClick={onClick}
        >
          Select plan
        </Button>

        {flags?.hide_onboarding_plan_details ? null : (
          <>
            <Typography className="mt-5" variant="subtitle1">
              What’s included?
            </Typography>
            <ul className="my-0 space-y-1 px-0 pt-2">
              <PlanFeature exec={isExec} feature={feature} />
              <Accordion
                className="block bg-transparent lg:hidden"
                elevation={0}
                disableGutters
              >
                <AccordionSummary
                  className="flex-row-reverse gap-2 p-0"
                  expandIcon={<IconExpandMore />}
                >
                  <Typography variant="caption">Expand offerings</Typography>
                </AccordionSummary>
                <AccordionDetails className="flex flex-col gap-2 p-0">
                  {additionalFeatures.map((feat) => (
                    <PlanFeature key={feat} exec={isExec} feature={feat} />
                  ))}
                </AccordionDetails>
              </Accordion>
              <div className="hidden flex-col gap-2 lg:flex">
                {additionalFeatures.map((feat) => (
                  <PlanFeature key={feat} exec={isExec} feature={feat} />
                ))}
              </div>
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export default Card;
