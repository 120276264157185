import { useMutation, useQuery } from '@tanstack/react-query';

import {
  deleteEvent,
  getEvents,
  patchClientEvent,
  patchEvent,
  postEvent,
} from '@/api';
import { QUERY_KEY_EVENTS } from '@/constants';

import type { GetEventsParams } from '@/api';
import type { Event } from '@/types';

export const useGetEvents = (params: GetEventsParams) =>
  useQuery<Event[], Error>({
    queryFn: () => getEvents(params),
    queryKey: [QUERY_KEY_EVENTS],
  });

export const useCreateEvent = () => useMutation({ mutationFn: postEvent });
export const useUpdateEvent = () => useMutation({ mutationFn: patchEvent });
export const useDeleteEvent = () => useMutation({ mutationFn: deleteEvent });

export const useUpdateClientEvent = () =>
  useMutation({ mutationFn: patchClientEvent });
