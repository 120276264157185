import type { Boulder } from './boulder';
import type { CardType } from './cards';
import type { Coach } from './coach';
import type { Company } from './company';
import type { Document } from './documents';
import type { Event } from './event';
import type { SurveyTemplate } from './insights';
import type { LegacySession } from './legacySession';
import type { ClientNotificationPreferences } from './notifications';
import type { Step } from './step';

export enum SortValues {
  MOST_RECENT = 'MOST_RECENT',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
}

export enum FilterValues {
  All = 'All',
  HasSessions = 'HasSessions',
  Inactive = 'Inactive',
  NoCoach = 'NoCoach',
  NotPaid = 'NotPaid',
  Paid = 'Paid',
}

// TODO: Once we have a better schema defined, most things should have a default
// this will make it much easier on the FE at basically no expense for the BE
export interface Client {
  additionalInfo: ClientAdditionalInfo | null;
  bio?: string | null;
  boulders?: Boulder[];
  careerGoals?: string[];
  challenges?: string[];
  coachClient?: unknown[];
  coaches?: Coach[];
  coachingNeeds?: string[];
  company: string | null;
  createdAt?: Date;
  documentFolderId?: string;
  documents?: Document[];
  dreamJobs?: string[];
  email: string;
  experience?: string | null;
  firstName: string;
  id: string;
  isActive: boolean | null;
  isBusinessPaid: boolean | null;
  isCoach?: boolean;
  lastLoginAt: Date | null;
  lastName: string;
  location: string | null;
  mindSet: Mindset | null;
  motivations?: string[];
  name: string;
  northStar: string | null;
  notes?: ClientNote[];
  oneYearVision: string | null;
  paymentCompany?: Company | null;
  paymentCustomerId?: string;
  paymentSubscription?: ClientSubscription;
  paymentSubscriptionId: string | null;
  paymentSubscriptionStatus: string | null;
  phone: string | null;
  preferences?: ClientPreferences;
  profileUrl?: string;
  pronouns?: string;
  sessions?: LegacySession[];
  status?: string;
  steps?: Step[];
  timezone: string | null;
  title: string | null;
}

export interface ClientPreferences {
  email?: boolean;
  notifications?: Partial<ClientNotificationPreferences>;
  phone?: boolean;
}

export interface ClientAdditionalInfo {
  checkIn?: number;
  growthPlan?: boolean;

  learnAbout?: boolean;

  linkedIn?: string;
  matchingPreference?: string;
  meetCoach?: boolean;
  // TODO Deprecated
  // TODO Remove when dashboard 2 is out
  profile?: boolean;
  providers?: {
    google?: {
      accessToken?: string;
      expiresAt?: string;
      refreshToken?: string;
    };
  };
  quizCompleted?: boolean;
  scheduled1on1?: string;
  scheduledMatchCall: number;
  website?: string;
  welcome?: Partial<ClientAdditionalInfoWelcome>;
}

// TODO Deprecated will need to update where we use this only issue is schedule1on1
export interface ClientAdditionalInfoWelcome {
  community?: boolean;
  learnMore?: boolean;
  meetCoach?: boolean;
  profile?: boolean;
  schedule1on1?: string;
}

export interface ClientNote {
  client?: Client;
  clientId: string;
  coach?: Coach;
  coachId: string | null;
  createdAt: Date;
  id: string;
  note: string;
  updatedAt: Date;
}

export interface PauseCollection {
  behavior: string;
  resumes_at: number;
}

export interface ClientSubscription {
  billing_cycle_anchor?: number;
  cancel_at?: number;
  canceled_at?: number;
  created: number;
  // End of the current period that the subscription has been invoiced for. At the end of this period, a new invoice will be created.
  current_period_end: number;
  // Start of the current period that the subscription has been invoiced for.
  current_period_start: number;
  customer: string;
  ended_at: number;
  id: string;
  pause_collection?: PauseCollection | null;
  plan?: {
    amount?: number;
    nickname?: string;
  };
  start_date: number;
  status: string;
}

export interface ClientEvent {
  client?: Client;
  clientId?: string;
  createdAt?: string;
  dismissedTill?: string;

  event?: Event;
  eventId?: string;

  id: string;
  key: CardType;

  updatedAt?: string;
  viewedAt?: string;
}

export interface ClientCompanyUpdate {
  isBusinessPaid: unknown;
  paymentCustomerId?: unknown;
  paymentSubscriptionId?: unknown;
  paymentSubscriptionStatus?: unknown;
}

export enum Mindset {
  calm = 'calm',
  confident = 'confident',
  curious = 'curious',
  dreaming = 'dreaming',
  enthusiastic = 'enthusiastic',
  focused = 'focused',
  grateful = 'grateful',
  hopeful = 'hopeful',
  reflective = 'reflective',
}

export interface CoachingStyleType {
  icon: string;
  subtitle: string;
  value: string;
}

export interface ClientHome {
  client: Client;
  clientEvents: ClientEvent[];
  events: Event[];
  peerInsight: SurveyTemplate;
  previousSessions: LegacySession[];
  upcomingSessions: LegacySession[];
}
